import React from "react"

const Blog = () => (
  <section id="blog">
    <div className="container flex-center">
      <div className="header">CHCESZ WIEDZIEĆ WIĘCEJ?</div>
      <div className="content">
        <a
          className="blog-link"
          href="https://blog.pawelzdralewicz.pl/"
          target="_blank"
          rel="noreferrer"
        >
        <div className="blog-box">
          <div className="blog-icon">
            <i className="fal fa-books"></i>
          </div>
          <div className="blog-text">
            <p>
              W wolnym czasie <span className="bold">tworzę blog</span>, na którym dzielę się swoją wiedzą
              związaną ze światem front-endu.
              Znajdziesz tam zarówno <span className="bold">fundamentalną wiedzę</span> przydatną na każdym etapie kariery programisty,
              jak i <span className="bold">zaawansowane zagadnienia</span>, których nie powstydziłby się nawet
              najbardziej zaprawiony w kodzie developer. <span className="bold">Zapraszam!</span>
            </p>
            <p className="centered-text">
              <span className="bold">Kliknij tutaj, aby przejść do bloga</span>
            </p>
          </div>
        </div>
        </a>
      </div>
    </div>
  </section>
)

export default Blog
