import React from "react"

const Values = () => (
  <section id="values">
    <div className="container flex-center">
      <div className="header">MOJE WARTOŚCI</div>
      <div className="content">
        <div className="values-box">
          <div className="values-icon">
            <i className="fal fa-lightbulb-on"></i>
          </div>
          <div className="values-content">
            <div className="values-header">Zrozumienie domeny biznesowej</div>
            <div className="values-text">
              Według mnie dobre rozumienie domeny biznesowej, w której się pracuje jest <span className="bold">kluczową wartością w pracy zespołów IT</span>.
              Znajomość domeny pozwala na <span className="bold">efektywne tworzenie rozwiązań</span> przybliżających organizację do realizacji celów.{" "}
              Zespół dobrze znający swój biznes jest w stanie proponować lepsze rozwiązania i zapobiegać trudnym przypadkom brzegowym.
            </div>
          </div>
        </div>

        <div className="values-box">
          <div className="values-icon">
            <i className="fal fa-comments"></i>
          </div>
          <div className="values-content">
            <div className="values-header">Otwartość i praca zespołowa</div>
            <div className="values-text">
              Wierzę, że otwartość w pracy zespołowej pozwala każdemu z członków na <span className="bold">rozwinięcie skrzydeł i stanie się najlepszą wersją siebie</span>.
              W takim środowisku pomysły same kiełkują, a przekuwanie ich w rzeczywistość jest czystą przyjemnością.
            </div>
          </div>
        </div>

        <div className="values-box">
          <div className="values-icon">
            <i className="fal fa-stars"></i>
          </div>
          <div className="values-content">
            <div className="values-header">Jakość</div>
            <div className="values-text">
              Wiele zespołów twierdzi, że <span className="bold">"nie ma czasu na zadbanie o jakość"</span>. Według mnie jest to <span className="bold">obowiązek zarówno zespołu, jak i każdego inżyniera z osobna</span>.
              Dbanie o jakość ułatwia życie wszystkim - daje pewność, że oprogramowanie zachowuje się zgodnie z oczekiwaniami, niezależnie od wprowadzanych zmian.{" "}
              <span className="bold">Regularne dbanie o jakość jest tańsze, niż późniejsza poprawa błędów wynikających z jej zaniedbania</span>.
            </div>
          </div>
        </div>

        <div className="values-box">
          <div className="values-icon">
            <i className="fal fa-transporter"></i>
          </div>
          <div className="values-content">
            <div className="values-header">Odpowiedzialność</div>
            <div className="values-text">
              Wierzę, że tylko poprzez branie pełnej odpowiedzialności za swoje czyny jesteśmy{" "}
              w stanie osiągnąć zaskakujące wyniki i rosnąć. Jestem zwolennikiem <span className="bold">stopniowego{" "}
              zwiększania poziomu odpowiedzialności</span> - zaczynając od najmniejszych zadań, a kończąc na odpowiedzialności za własny zespół.
            </div>
          </div>
        </div>

        <div className="values-box">
          <div className="values-icon">
            <i className="fal fa-seedling"></i>
          </div>
          <div className="values-content">
            <div className="values-header">Rozwój</div>
            <div className="values-text">
              Ciągły rozwój podparty solidnym fundamentem jest niezbędny w dynamicznie rozwijającym się świecie{" "}
              inżynierii oprogramowania. Wierzę w zrównoważony rozwój - <span className="bold">warto wiedzieć wszystko o czymś i coś o wszystkim</span>.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Values;
