import React from 'react';

const WhatImGoodAt = () => (
  <section id="what-im-good-at">
    <div className="container flex-center">
      <div className="header">
        W CZYM JESTEM DOBRY?
      </div>
      <div className="content">
        <div className="wiga-box">
          <div className="wiga-box-icon">
            <i className="fal fa-users"></i>
          </div>
          <div className="wiga-box-header">
            Leadership
          </div>
          <div className="wiga-box-text">
            Mam doświadczenie w <span className="bold">budowaniu i prowadzeniu zespołów IT</span>. Rekrutacje,{" "}
            ścieżki rozwoju, codzienna praca w oparciu o <span className="bold">jasne oczekiwania i szczery feedback</span> -{" "}
            wszystko w myśl <span className="bold">zwinnych metodyk</span> zarządzania.
          </div>
        </div>

        <div className="wiga-box">
          <div className="wiga-box-icon">
            <i className="fal fa-chalkboard-teacher"></i>

          </div>
          <div className="wiga-box-header">
            Mentoring
          </div>
          <div className="wiga-box-text">
            Chętnie biorę pod swoje skrzydła mniej doświadczone osoby. Za pomocą wspólnego <span className="bold">projektowania, {" "}
            pair programming'u, czy szczegółowego code review</span> bardzo szybko wyrównamy poziom w zespole.
          </div>
        </div>

        <div className="wiga-box">
          <div className="wiga-box-icon">
            <i className="fal fa-pencil"></i>
          </div>
          <div className="wiga-box-header">
            Projektowanie rozwiązań
          </div>
          <div className="wiga-box-text">
            Przekuję Twoją <span className="bold">potrzebę biznesową</span> w <span className="bold">projekt rozwiązania</span>. Jeśli będzie to potrzebne,{" "}
            <span className="bold">przeprowadzę refinement,</span> by pogłębić zrozumienie materii i wyrównać wiedzę w zespole.
          </div>
        </div>

        <div className="wiga-box">
          <div className="wiga-box-icon">
            <i className="fal fa-code"></i>
          </div>
          <div className="wiga-box-header">
            Development
          </div>
          <div className="wiga-box-text">
            Mam niemal 7-letnie doświadczenie w komercyjnym tworzeniu <span className="bold">front-endu rozwiązań desktopowych, mobilnych i webowych</span>.
            Chętnie poszerzam swoje horyzonty i lubię technologiczne eksperymenty.
          </div>
        </div>

      </div>
    </div>
  </section>
);

export default WhatImGoodAt;
