import React from "react"

import Layout from "../components/layout"
import SEO from "../components/pl/seo"
import Logo from "../components/logo"
import Home from "../components/pl/home"
import Blog from "../components/pl/blog"
import About from "../components/pl/about"
import WhatImGoodAt from "../components/pl/what-im-good-at"
import Values from "../components/pl/values"
import Footer from "../components/footer"

// TODO: zaktualizować angielską stronę i dodać przycisk przełączania języka

const IndexPage = () => (
  <Layout>
    <SEO title="Paweł Zdralewicz - Portfolio" />
    <Logo />
    <Home />
    <About />
    <Blog />
    <WhatImGoodAt />
    <Values />
    <Footer />
  </Layout>
)

export default IndexPage
