import React from "react"
import Image from "../image"

const About = () => (
  <section id="about">
    <div className="container flex-center">
      <div className="header">KIM JESTEM?</div>
      <div className="content">
        <a
          className="linkedin-link"
          href="https://www.linkedin.com/in/pzdralewicz/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="about-box">
            <div className="about-photo">{Image().photo}</div>
            <div className="about-text">
              <p>
                Jestem <span className="bold">inżynierem oprogramowania</span>.
                Specjalizuję się w tworzeniu{" "}
                <span className="bold">front-endu</span> aplikacji webowych,
                mobilnych i desktopowych. Lubię też czasem przeskoczyć{" "}
                na drugą stronę mocy i pogrzebać w
                back-endzie / na chmurze. Obecnie pełnię rolę{" "}
                <span className="bold">lidera zespołu developerskiego</span> w{" "}
                <span className="bold">SmartFactor</span>, a gdy tylko nadarzy
                się okazja, wchodzę też w buty{" "}
                <span className="bold">mentora technicznego</span> na{" "}
                <span className="bold">hackathonach</span>.
              </p>
              <p>
                Programowanie jest moją <span className="bold">pasją</span> od
                najmłodszych lat.{" "}
                <span className="bold">
                  Stale poszerzam swoją księgę programistycznych czarów
                </span>
                , tak by każdy kolejny projekt cieszył oko i zachwycał jakością.{" "}
                W codziennej współpracy{" "}
                <span className="bold">cenię otwartość i pracę zespołową</span>.{" "}
                Według mnie to one pozwalają na{" "}
                <span className="bold">
                  dobre zrozumienie materii i uszycie softu na miarę
                </span>
                .
              </p>
              <p>
                Technologie, w których się specjalizuję to:{" "}
                <span className="bold">
                  React / React Native, Redux, RTK (+Query), Gatsby, Electron,
                  TypeScript, SCSS, CSS-in-JS, Firebase, Docker
                </span>{" "}
                oraz biblioteki z nimi powiązane. Lwia część projektów, które
                realizowałem oparta była o{" "}
                <span className="bold">dane geoprzestrzenne</span>, dlatego{" "}
                <span className="bold">Openlayers i Google Maps</span> znam od podszewki. Informacje o projektach,
                które realizowałem znajdziesz na moim profilu LinkedIn.
              </p>
              <p className="centered-text">
                <span className="bold">
                  Kliknij tutaj, aby przejść na LinkedIn
                </span>
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </section>
)

export default About
